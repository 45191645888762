.main-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: grid;
    place-items: center;
    height: 100%;
      .child-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-around;
        padding: 5rem 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100%;
        gap: 2rem;
  
        position: relative;
        .title {
          font-size: 2rem;
          font-weight: bold;
        }
        .content {
          font-size: 2rem;
          font-weight: 400;
          line-height: 1.5;
        }
      }

    }
    @media (max-width: 768px) {
      .main-container{
        padding: 2rem;
          background-size:initial;
          .child-container {
              padding: 2rem 0;
              gap: 1rem;
              .title {
                font-size: 1.2rem;
              }
              .content {
                font-size: .8rem;
                line-height: 1.2;
              }
            }
      }}
  