.testimonial-container {
  padding-top:20px;
  margin: 0 auto;
  background: #C1C2FE;
  background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
  padding-bottom:30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  .title2 {
    text-align: center;
    font-size:1.5rem;
    font-weight:800;
    padding:30px;
    color:#fff;
  }
}
.testimonial-card {
  display: flex;
  align-items: center;
  padding: 18px;
  background-color: #fff;
  border-radius: 8px;
  max-width: 75vw;
  margin: 0 auto 20px auto; 
  
} 
.logo-column {
  flex: 0 0 auto; 
  margin-right: 25px; 
}
.message-column {
  flex: 1; 
  font-weight:400;
  color:#121212;
  .name {
    font-size:1.25rem;
    color:#121212;
    font-weight:600;
  }
  .title {
    font-size:.75rem;
    color:gray;
    font-weight:400;
  }

}

.logo-column img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .testimonial-card {
    flex-direction: column;
  }
  .logo-column {
    flex: 0 0 auto; 
    margin-right: 25px; 
  }
  .message-column {
    flex: 1; 
  }

}