.about {
    text-align: center;
    min-height: 100dvh;
    background: url('https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/aboutsection.jpg') no-repeat center/cover;
    background-color: #000;
    display: grid;
    place-items: center;
    padding: 3rem;  

    .about-container{
        text-align: left;
        display: grid;
        background-color: #1a6cbe97;
        color: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        padding: 3rem;  

        .about-title{
            font-size: 4rem;
            font-weight: 500;
        }
        .about-text{
            font-size: 1.8rem;
            font-weight: 400;
        }
        @media (max-width: 768px) {
            .about-title {
                font-size: 1.2rem; 
            }
            .about-text {
                font-size: .8rem; 
            }
        }
    }



  }
  
  @media (max-width: 768px) {
      .about{
        min-height: 50dvh;
      }

  }