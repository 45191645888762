@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.header-container {
  background-size: cover;
  background-position: center;
  font-family: 'Poppins', sans-serif;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100dvh;
  overflow: hidden;
  text-align: center;

  .header-content {
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3.5rem;
    position: relative;
    z-index: 99;
    color: #fff;
    padding: 2rem;
    text-shadow: 2px 2px #0000002b;

    .title {
      font-size: 3.5rem;
      font-weight:normal;
    }
    
    .subtitle {
        font-size: 4.5rem;
        font-weight:900;
      margin-top: 10px;
    }
    .matter {
      font-size: 1.2rem;
      font-weight: 400;
      color: #fff;
      text-align: center;
      max-width: 50%;
      margin: 0 auto;
      font-weight: 400; 
      line-height: 1.8; 
      word-wrap: break-word;
    }
  }
}
@media (max-width: 768px) {
  .header-container {
    height: 33dvh;
    .header-content{
        background-size: initial;
        height: 33dvh;
        gap: .5rem;
      .title{
        font-size: .7rem;
      }
      .subtitle {
        font-size: 1.2rem;
        margin-top: 5px;
      }
      .matter{
        font-size: .5rem;
        max-width: 95%;
      }
    }
  }
}
