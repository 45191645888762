.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 97%;
  z-index: 1000;
  color: #fff;

  .logo-placeholder {
    display: flex;
    align-items: center;
    gap: 1rem;

    .company-name {
      font-size: 1.2rem;
      font-weight: 600;
    }
    img {
      width: auto;
      height: 2.5rem;
    }
  }

  .hamburger {
    display: none;
    background: none;
    border: none;
    font-size: 1.3rem;
    cursor: pointer;
    color: #fff;
    margin-right: 1rem;
  }

  .nav-links {
    display: flex;
    list-style: none;

    li {
      margin: 0 10px;
      position: relative;

      .dropdown-toggle {
        background: none;
        border: none;
        font-size: 16px;
        color: #fff;
        cursor: pointer;

        &:hover {
          color: #fff;
        }
      }

      a {
        text-decoration: none;
        color: #fff;
        font-size: 16px;

        &:hover {
          color: #fff;
        }
      }

      .dropdown-menu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        list-style: none;
        padding: 10px 0;
        z-index: 1000;
        background: rgba(144, 201, 243, 0.15);
        border-radius: 4px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(12.7px);
        -webkit-backdrop-filter: blur(12.7px);

        li {
          padding: 5px 20px;

          a {
            color: #fff;

            &:hover {
              color: #fff;
            }
          }
        }
      }

      & .dropdown-menu {
        display: block;
      }
    }
  }

  .ant-btn{
    padding: 0;
    height:fit-content;
    line-height: normal;
    color: #fff;
  }

  @media (max-width: 768px) {
    .navbar{
        width: 93%;
    }
    .hamburger {
      display: block;
    }

    .logo-placeholder {
        display: flex;
        align-items: center;
        gap: 1rem;
    
        .company-name {
          font-size: .8rem;
          font-weight: 600;
          line-height: normal;
        }
        img {
          width: auto;
          height: 2rem;
        }
      }

    .nav-links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 2rem;
      right: 2.5rem;
      padding: 1rem 2rem;
      background: rgba(144, 201, 243, 0.15);
      border-radius: 4px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(12.7px);
      -webkit-backdrop-filter: blur(12.7px);

      &.open {
        display: flex;
        z-index: 1;
      }

      li {
        margin: 10px 0;
        color: #fff;

        .dropdown-menu {
          position: relative;
          top: auto;
          box-shadow: none;
        }
      }
    }
  }

}

@media (max-width: 768px) {
  .navbar{
      max-width: 93%;
  }}