.clients-container {
    margin: 0 auto;
  }
  
  .label {
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .testimonials {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .testimonial-card {
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
  }
  
  .client-name {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .quote {
    font-size: 1rem;
  }
  