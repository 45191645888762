.career-container {
    margin: 0 auto;
   
  }
  .job-posting{
    background-color: #F9F6F6;
    margin:0 auto;
    padding-bottom:30px;
    background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
    .img {
      position:relative;
      top:30px;
      left:10px;
      opacity:2px;
      z-index:2;
    }
    .title2 {
      text-align: center;
      font-size:1.5rem;
      font-weight:800;
      padding:30px;
      color:#ffff;
    }
  }
  .label {
    font-weight: bold;
    margin-bottom: 15px;
    margin:0 auto;
    align-items: center;
  }
  
  .job-list {
    display: flex;
    z-index:9999;
    align-items: left;
    padding: 18px;
    background-color: #fff;
    border-radius: 8px;
    max-width: 65vw;
    margin: 0 auto 20px auto; 
    flex-direction: column;
    
  }
  .message {
    text-align: center;
    font-weight:400;
    color:#121212;
    line-height: 32px;;

  }
  .job-card {
    border-radius: 8px;
  }
  
  .job-title {
    font-weight: 500;
    margin-bottom: 10px;
  }
  
  .job-description {
    font-size: 1rem;
  }
  
  @media (max-width: 768px) { 
    .job-list {
      max-width: 75vw;

    }
  }