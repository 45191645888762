.w-header-container{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    height: 100dvh;
    overflow: hidden; 

    .video-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; 
        z-index: 1;
        opacity:0.5;
      }
    .w-header-container-inner{
        height: 100dvh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        z-index: 99;
        color: #fff;
        padding: 2rem;
    }
    
}
@media (max-width: 768px) {
    .w-header-container{
        height: 33dvh;   
        .video-background {
            max-height: 33dvh;
        }
        .w-header-container{
            background-size: initial;
        }
        .w-header-container-inner{
            height: 33dvh;
        }
    }
}


  