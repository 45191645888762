.mission-vision-container {
    margin: 0 auto;
  }
  
  .card {
    padding: 15px;
    background-color: #f0f8ff;
    border-radius: 8px;
  }
  
  .label {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .content {
    font-size: 1rem;
  }
  