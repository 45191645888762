.footer {
  background: #ebebeb;
  padding: 2rem 4rem;
  font-family: 'Poppins', sans-serif;

  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;

    .footer-top {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      .logo-placeholder {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        .company-name {
          font-size: 1rem;
          font-weight:600;
        }
        img {
          height: 2.5rem;

          .company-name {
            font-size: 1.2rem;
            font-weight:600;
          }
        }
      }

      .footer-social {
        display: flex;
        justify-content: center;
        gap: 1rem;

        .social-icon {

          &:hover {
            background-color: #007bff;
            color: #000;
          }
        }
      }
    }
    .footer-content {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      width: 95%;
      justify-content: space-between;

      .links {
        line-height: 2;
      }

      .footer-section {
        display: flex;
        gap: 4rem;
        .sub-container {
          display: flex;
          row-gap: 2rem;
          flex-wrap: wrap;
          column-gap: 9rem;
          line-height: 1.5;
        }

        .footer-title {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 1.5rem;
          color: #000;
        }
        .footer-text {
          font-size: 1rem;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            margin-bottom: 0.5rem;

            a {
              text-decoration: none;
              color: #000;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  .footer-bottom {
    margin-top: 2rem;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 1rem 2rem;
    line-height: 1;
    .footer-container {
      .footer-top {
        justify-content: center;
        gap: .5rem;

        .logo-placeholder .company-name{
          font-size: .8rem;
        }
      }

      .footer-content {
        flex-direction: column;
        width: 100%;

        .footer-section {
          justify-content: space-between;
         .footer-title {
          font-size: .8rem;
          margin-bottom: .5rem;
         }
         .footer-text{
          font-size: .8rem;
         }
        }
      }
    }
    .footer .footer-bottom {
      font-size: 1rem;
    }
  }
}
