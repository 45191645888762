.main-container {
  display: grid;
  place-items: center;
  padding: 5rem;
  .child-container-wrapper {
    border-radius: 8px;
    .child-container {
      display: flex;
      flex-direction: column;
      align-items: start;
      padding: 5rem 2rem;
      justify-content: space-around;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      min-height: 55dvh;
      gap: 2rem;

      position: relative;
    //   height: 70dvh;
      .cardTitle {
        font-size: 2.5rem;
        font-weight: bold;
      }
      .cardContent{
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.5;
        word-wrap: break-word;
      }
    }
  }
}

@media (max-width: 768px) {
  .main-container{
    padding: 2rem;
      background-size:initial;
      .child-container-wrapper {
        border-radius: 8px;
        .child-container {
          padding: 3rem 2rem;
          justify-content: space-evenly;
          min-height: 0;
          gap: 2rem;
          .cardTitle {
            font-size: 1.25rem;
          }
          .cardContent{
            font-size: 1rem;
            line-height: 1.2;
          }
        }
      }
  }
}
