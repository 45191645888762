@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.testimonial {
  min-height: 60dvh;
  background: url("https://ese-web-store.s3.ap-south-1.amazonaws.com/assets/backgrounds/team.png") no-repeat
    center/cover;
  background-color: #283594;
  font-family: 'Poppins', sans-serif;
  display: grid;
  place-items: center;
  padding: 3rem 5rem;

  .testimonial-text {
    font-size: 2rem;
    color: #fff;
    text-align: center;
    max-width: 85%;
    margin: 0 auto;
    font-weight: 400; 
    line-height: 1.8; 
    word-wrap: break-word;
  }
  @media (max-width: 768px) {
      .testimonial-text {
          font-size: 1rem; 
      }
  }
}
@media (max-width: 768px) {
  .testimonial {
    min-height: fit-content;
    padding: 2rem 1rem;
  }
}
