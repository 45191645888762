@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.hero {
  font-family: 'Poppins', sans-serif;
  display: grid;
  place-items: center;
  
  
  .hero-content{
    padding: 3rem 5rem;
    text-align: left;
    .hero-title {
      font-size: 4rem;
      color: #ffff;
      font-weight: 600; 
      margin-bottom: 4rem;
    }
    .hero-text {
      font-size: 1.25rem;
      color: #fff;
      padding-top:5rem;
      text-align: left;
    }
    
    @media (max-width: 768px) {
        .hero-title {
            font-size: 1rem; 
            margin-bottom: 0;
        }
        .hero-text {
            font-size: .6rem; 
        }
    }
  }
  @media (max-width: 768px) {
    .hero-content{
      padding: 0;
      text-align: left;
      margin: 0 0 2rem 0px;
    }}

}

