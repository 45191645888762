.about-us-container {
  margin: 0 auto;
}

.overview {
  margin-bottom: 20px;
}

.details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.card {
  padding: 15px;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.label {
  font-weight: bold;
  margin-bottom: 10px;
}

.content {
  font-size: 1rem;
}
